<form #f="ngForm" novalidate (submit)="onSave()">

    <k-dialog title="{{'REPORTS.TIME_SHEET.TITLE' | translate }}">

        <k-dialog-content class="content" fxLayout="column">
            <!-- <mat-tab-group>
                <mat-tab label="{{'REPORTS.TIME_SHEET.GENERAL' | translate }}"> -->
                    <k-alert class="alertMessage"
                        *ngIf="defaultMessage"
                        [message]="defaultMessage"
                    >
                    </k-alert>
                    <div class="general" fxLayout="row">
                        <mat-form-field fxFlex="48%">
                            <input matInput [matDatepicker]="start"
                                placeholder="{{ 'REPORTS.TIME_SHEET.START_DATE' | translate }}" required
                                [(ngModel)]="startDate" name="start">
                            <mat-error *ngIf="f.form.controls.start?.invalid">{{ 'INVALID_DATE' | translate }}</mat-error>
                            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                            <mat-datepicker #start></mat-datepicker>
                        </mat-form-field>

                        <span fxFlex></span>

                        <mat-form-field fxFlex="48%">
                            <input matInput [matDatepicker]="end"
                                placeholder="{{ 'REPORTS.TIME_SHEET.END_DATE' | translate }}" required
                                [(ngModel)]="endDate" name="end">
                            <mat-error *ngIf="f.form.controls.end?.invalid">{{ 'INVALID_DATE' | translate }}</mat-error>
                            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                            <mat-datepicker #end></mat-datepicker>
                        </mat-form-field>
                    </div>
               <!-- </mat-tab>
                 <mat-tab label="{{'REPORTS.TIME_SHEET.EVENTS' | translate }}">
                    <div class="definedEvents" fxLayout="row" fxLayoutAlign="start space-between"
                        *ngFor="let definedEvent of definedEvents">
                        <mat-checkbox [checked]="definedEvent.selected" name="definedEvent.description"></mat-checkbox>
                        <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
                            <span class="value">{{definedEvent.description}}</span>
                        </div>
                        <div class="acronym" fxLayout="column" fxFlex="80px" fxLayoutAlign="center center">
                            <span class="value">{{definedEvent.acronym}}</span>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>-->
        </k-dialog-content>

        <k-dialog-footer>
            <button mat-button type="submit" [disabled]="!f.valid" k-wait-request>{{ 'ACTIONS.SEND' | translate }}</button>
        </k-dialog-footer>

    </k-dialog>
</form>
