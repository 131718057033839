import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
import { MessageService } from '../core/services/message.service';
import { MessageKeys } from '../models/message-keys.model';

@Directive({ selector: '[k-wait-request]' })
export class KWaitRequestDirective implements OnInit {

    constructor(private _el: ElementRef, private _renderer: Renderer2, private _messenger: MessageService) {}

    ngOnInit(): void {
        this._messenger.subscribe(MessageKeys.DisableWaitResponseActions, (value: boolean) => {

                if(value){
                    this._renderer.setAttribute(this._el.nativeElement, 'disabled', 'true');
                } else {
                    this._renderer.removeAttribute(this._el.nativeElement, 'disabled');
                }
        });
    }
}
